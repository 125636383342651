/* eslint-disable */
import PageLayout from '../Layouts/PageLayout'
import {
    Ellipse,
    RecordedRoomBadge,
    RecordIcon,
    RecordText,
    SingleUserContainer,
    UserGridDataContainer,
    UserImage,
    UserImageContainer,
    UserListHeadingContainer,
    UserListHeadingText,
    UserListInnerContainer,
    UserListMainContainer,
    UsernameContainer,
    UsernameText,
    UserRole,
    UserRoleText,
    VerifiedUserIcon,
    VIPLiveBadge,
    VIPStarIcon,
    VIPText,
} from '../Pages/LivestreamPage/styles'
import { PlaybackRateMenu } from './PlaybackRateMenu'
import { ThreeDotMenu } from './ThreeDotMenu'
import { TooltipContainer } from './Tooltip'
import { UnMuteTooltip } from './UnMuteTooltip'
import {
    CatalogTitleAndFollowContainer,
    CatalogTitleText,
    CatalogueTitle,
    Container,
    ContentImage,
    ContentTitleAndCatalogueNameContainer,
    ControlsContainer,
    ControlsIcon,
    ControlsPlayIcon,
    DurationContainer,
    DurationText,
    EpisodeTitle,
    ExitFullScreenButton,
    FollowButtonContainer,
    FollowText,
    FullScreenContainer,
    HeaderContainer,
    InfoOutline,
    LiveHeaderParticipantCountAndText,
    LiveroomHeaderContainer,
    LiveroomTitle,
    PlayerContainer,
    PlayerInformation,
    PlayerSlider,
    PlayIcon,
    PlayingContentInformation,
    RadioTitle,
    Slider,
    ThumbnailContainer,
    ThumbnailImage,
    Title,
    PlayIconContainer,
    PlayPauseContainer,
    IconButton,
    SoundSliderContainer,
    ImageTitleContainer,
    PlayerHeader,
    SwitchContainer,
    AudioButton,
    VideoButton,
    VideoControlContainer,
    ReactPlayerContainer,
    MobileVideoControlContainer,
    MobilePrevNext,
    RadioFavorite,
    DesktopOnly,
    MobileOnly,
    VideoOverlayOnHover,
    AdButtonOnMiniplayer,
    AdButtonOnMobile,
    MobileSlider,
    MobileSliderContainer,
} from '@/components/AudioPlayer/styles'
import { addParamsToUrl } from '@/helpers/addParamsToUrl'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import compressedImage from '@/helpers/compressedImage'
import { compressedUserPhoto } from '@/helpers/compressedUserPhoto'
import { EventTracking } from '@/helpers/eventTracking'
import { TRIGGER_TIME_TO_PLAY } from '@/helpers/events'
import { getAdTrackingEventParams } from '@/helpers/getAdTrackingEventParams'
import { getClickthroughLinkFromAdContent } from '@/helpers/getClickthroughLinkFromAdContent'
import getOldUsersListenData from '@/helpers/getOldUsersListenData'
import { getTTagString } from '@/helpers/getTTagString'
import { handleAdDuration } from '@/helpers/handleAdDuration'
import NumberConversion from '@/helpers/numberConversion'
import { radioListenerCountHandler } from '@/helpers/radioListenerHandler'
import { resumeContentAfterAd } from '@/helpers/resumeContentAfterAd'
import setUsersListenData from '@/helpers/setUsersListenData'
import { useCatalogSubscribed } from '@/helpers/useCatalogSubscribed'
import { initializeAds } from '@/store/actions/ads'
import { updateCollectionElapsedTime } from '@/store/actions/collection'
import { initializeCompanionAds } from '@/store/actions/companionAds'
import { updateContentElapsedTime } from '@/store/actions/content'
import { updateElapsedTime } from '@/store/actions/home'
import {
    setCurrentPlayingCatalog,
    setCurrentTrackId,
    setPlaying,
    setPlayingRadioListenersCount,
    setShowDownloadPrompt,
    setShowPlayer,
    setAutoPlayed,
    setSoundValue,
    setToggleScreen,
    setTrackCount,
    setElapsedTime,
    setShowLoginPrompt,
} from '@/store/actions/player'
import * as adsSelector from '@/store/selectors/ads'
import * as adsSettingsSelectors from '@/store/selectors/adsSettings'
import * as catalogSelectors from '@/store/selectors/catalog'
import { _segmentName } from '@/store/selectors/common'
import * as companionAdsSelector from '@/store/selectors/companionAds'
import * as imaSelector from '@/store/selectors/ima'
import * as playerSelectors from '@/store/selectors/player'
import * as pubnubSelector from '@/store/selectors/pubnub'
import { leave } from '@/utils/agora'
import {
    initializeContentPlayPauseAPI,
    subscribeToCatalog,
} from '@/utils/api-ssr'
import { db } from '@/utils/firebase'
import { Slider as SoundSlider } from 'antd'
import dayjs from 'dayjs'
import { collection, onSnapshot } from 'firebase/firestore'
import fscreen from 'fscreen'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { deviceType, isDesktop, isIOS, isMobile } from 'react-device-detect'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'

// Dayjs Plugins
const durationPlugin = require('dayjs/plugin/duration')
dayjs.extend(durationPlugin)

const AudioPlayer = () => {
    const router = useRouter()
    const dispatch = useDispatch()
    const { data: session } = useSession()
    const isLoggedin = session?.isLoggedin
    const userId = session?.userInfo?.id
    const token = session?.token
    const pubnub = useSelector(pubnubSelector._pubnub)
    // ad count
    let adCount = 1

    // Ads eligibility
    const [adsEnabled, setAdsEnabled] = useState(false)

    // Ads Settings
    const adsSettings = useSelector(adsSettingsSelectors._adsSettings)
    const companionAds = useSelector(companionAdsSelector._companionAds)

    // Ads
    const ads = useSelector(adsSelector._ads)
    const imaConfig = useSelector(imaSelector._ima)

    //The order of variables should be global state, internal state and declared variables
    const playlist = useSelector(playerSelectors._playlist)
    const playing = useSelector(playerSelectors._playing)
    const catalogData = useSelector(catalogSelectors._catalogData)
    const showPlayer = useSelector(playerSelectors._showPlayer)
    const toggleScreen = useSelector(playerSelectors._toggleScreen)
    const autoPlayed = useSelector(playerSelectors._autoPlayed)
    const soundValue = useSelector(playerSelectors._soundValue)
    const trackCount = useSelector(playerSelectors._trackCount)
    const currentPlayingCatalog = useSelector(
        playerSelectors._currentPlayingCatalog
    )

    const currentVertical = currentPlayingCatalog?.type
    const joinedLiveroom = useSelector(playerSelectors._joinedLiveroom)
    const currentLiveroom = useSelector(playerSelectors._currentLiveroom)
    const liveSpeakers = useSelector(playerSelectors._currentLiveSpeakers)
    const liveroomParticipantCount = useSelector(
        playerSelectors._currentLiveroomParticipantCount
    )
    const currentTrackId = useSelector(playerSelectors._currentTrackId)
    const { segmentId, t } = router.query
    const segmentName = useSelector(_segmentName)
    const [timeElapsed, setTimeElapsed] = useState(0)
    const [previouslyPlayedSeconds, setPreviouslyPlayedSeconds] = useState(0)
    const [seeking, setSeeking] = useState(false)
    const [duration, setDuration] = useState(0)
    const [played, setPlayed] = useState(0)
    const [startType, setStartType] = useState('manual')
    const [playedSeconds, setPlayedSeconds] = useState(0)
    const [playedDurationInString, setPlayedDurationInString] =
        useState('00:00')
    const [timeToPlayTriggered, setTimeToPlayTriggered] = useState(false)
    const pathname = router.asPath
    const playerRef = useRef()
    let startTime = 0
    let adStartTime = 0
    const [firebaseAdEventParams, setFirebaseAdEventParams] = useState(null)
    const [amplitudeAdEventParams, setAmplitudeAdEventParams] = useState(null)
    const [playbackrRate, setPlaybackRate] = useState(1)
    const [mouseMoved, setMouseMoved] = useState(false)

    // Ad related States

    const [adDurationData, setAdDurationData] = useState({
        duration: null,
        playedSeconds: null,
        playedDurationInString: null,
        leftDurationInString: null,
        actualPlayed: 1,
    })

    const [adsManager, setAdsManager] = useState(null)
    const [adVideoContent, setAdVideoContent] = useState(null)
    const [ad, setAd] = useState(null)
    const [userEligibleForAds, setUserEligibleForAds] = useState(false)
    const [startListeningTime, setStartListeningTime] = useState(0)
    const [currentTrackIdLocal, setCurrentTrackIdLocal] = useState(null)
    const [isReady, setIsReady] = useState(false)
    const [videoSelected, setVideoSelected] = useState(false)
    const [showFullScreen, setShowFullScreen] = useState(false)
    const [previouseTimeStamp, setPreviousTimeStamp] = useState(0)

    const { subscribed, mutate, followers } = useCatalogSubscribed(
        currentPlayingCatalog?.id
    )

    const isNoicebook = useMemo(
        () =>
            ['audiobook', 'audioseries'].includes(currentPlayingCatalog?.type),
        [currentPlayingCatalog]
    )
    useEffect(() => {
        if (t > 0 && !isNaN(t)) {
            setTimeElapsed(t)
        }
    }, [t])
    useEffect(() => {
        if (
            playing &&
            currentTrackIdLocal !== currentTrackId &&
            currentTrackIdLocal
        ) {
            let events = [
                {
                    contentId: currentTrackIdLocal,
                    event: 'pause',
                    payload: {
                        entityId: currentTrackIdLocal,
                        entityType: 'content',
                        durationPlayed: Math.round(playedSeconds),
                        listeningTime: dayjs
                            .duration(Date.now() - startListeningTime)
                            .seconds(),
                    },
                },
            ]

            playPauseContent(events)
            dispatch(
                setElapsedTime({
                    id: currentTrackIdLocal,
                    timeElapsed: playedSeconds,
                })
            )
            dispatch(
                updateElapsedTime({
                    id: currentTrackIdLocal,
                    timeElapsed: playedSeconds,
                })
            )
            dispatch(
                updateCollectionElapsedTime({
                    id: currentTrackIdLocal,
                    timeElapsed: playedSeconds,
                })
            )
        }

        setCurrentTrackIdLocal(currentTrackId)
    }, [currentTrackId, playing, startListeningTime])

    // START: ADS

    // Check and set andEnabled basd on the current content and verticals
    // ads settings
    useEffect(() => {
        if (!currentPlayingCatalog?.type) return

        let adEnabled = false
        const { displayAds: contentDisplayAds } = playlist[trackCount] ?? {
            displayAds: false,
        }
        const { displayAds: catalogDisplayAds, type: vertical } =
            currentPlayingCatalog
        const catalogDisplayAdsIsNull =
            catalogDisplayAds === null || catalogDisplayAds === undefined

        if (catalogDisplayAdsIsNull) {
            adEnabled = contentDisplayAds
        } else if (vertical === 'radio') {
            adEnabled = catalogDisplayAds
        } else if (catalogDisplayAds && contentDisplayAds) {
            adEnabled = true
        }

        setAdsEnabled(
            adEnabled && adsSettings.hasOwnProperty(currentPlayingCatalog?.type)
        )
    }, [playlist, adsSettings, currentPlayingCatalog])

    // Setup adsLoader after it has been initialized
    useEffect(() => {
        if (!imaConfig.adsLoader || !userEligibleForAds) return
        const adsLoader = imaConfig.adsLoader
        const currentAdSettings = adsSettings[currentPlayingCatalog?.type]

        adsLoader.addEventListener(
            google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            (adsManagerLoadedEvent) => {
                // Get the ads manager.
                const adsRenderingSettings =
                    new google.ima.AdsRenderingSettings()
                adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true

                // adVideoContent should be set to the content video element.
                setAdsManager(
                    adsManagerLoadedEvent.getAdsManager(
                        adVideoContent,
                        adsRenderingSettings
                    )
                )
            },
            false
        )

        adsLoader.addEventListener(
            google.ima.AdErrorEvent.Type.AD_ERROR,
            (event) => {
                resumeContentAfterAd(
                    event,
                    dispatch,
                    initializeCompanionAds,
                    initializeAds,
                    setPlaying,
                    adDurationCountInterval,
                    true,
                    setUserEligibleForAds
                )
            },
            false
        )

        // Request video adsState.
        const adsRequest = new google.ima.AdsRequest()
        const adRequestUrl = addParamsToUrl(currentAdSettings.url, {
            ttag: getTTagString(playlist[trackCount], currentPlayingCatalog),
        })

        adsRequest.adTagUrl = adRequestUrl

        // Specify the linear and nonlinear slot sizes. This helps the SDK to
        // select the correct creative if multiple are returned.
        adsRequest.linearAdSlotWidth = 640
        adsRequest.linearAdSlotHeight = 533
        adsRequest.nonLinearAdSlotWidth = 640
        adsRequest.nonLinearAdSlotHeight = 533

        adsLoader.requestAds(adsRequest)
        if (session?.token) {
            EventTracking(
                'ad_requested',
                {
                    adMediaType: 'audio',
                    adNumber: currentAdSettings.maxNumberAdsPlaying,
                },
                session
            )
        }
    }, [imaConfig.adsLoader, userEligibleForAds])

    let adDurationCountInterval = null

    // Start playing ads after adsManager has been initialized
    useEffect(() => {
        if (!adsManager) return
        adsManager.init(640, 360, google.ima.ViewMode.NORMAL)

        adsManager.start()

        adsManager.addEventListener(
            google.ima.AdEvent.Type.LOADED,
            (adEvent) => {
                const ad = adEvent.getAd()
                const key = Object.keys(ad)[0] || null
                if (key && ad[key]) {
                    setAd(ad[key])
                }
            }
        )

        adsManager.addEventListener(
            google.ima.AdEvent.Type.PAUSED,
            (adEvent) => {
                clearInterval(adDurationCountInterval)

                if (session?.token) {
                    AmplitudeEventTracking(
                        'ad paused',
                        amplitudeAdEventParams,
                        session
                    )
                    EventTracking('ad_paused', firebaseAdEventParams, session)
                }
            }
        )

        adsManager.addEventListener(
            google.ima.AdEvent.Type.RESUMED,
            (adEvent) => {
                adDurationCountInterval = handleAdDuration(
                    adEvent,
                    adsManager,
                    adDurationData,
                    setAdDurationData
                )
            }
        )

        adsManager.addEventListener(
            google.ima.AdEvent.Type.STARTED,
            (adEvent) => {
                const oldUsersListenData = getOldUsersListenData()
                setUsersListenData(oldUsersListenData, {
                    [currentVertical]: {
                        contentPlayed: 0,
                        secondsListened: 0,
                        currentPlaytime: 0,
                        lastListened: null,
                    },
                })

                dispatch(
                    initializeAds({
                        adPlaying: true,
                        showingAd: true,
                    })
                )

                handlePause()

                const ad = adEvent.getAd()

                const currentAdSettings =
                    adsSettings[currentPlayingCatalog.type]

                const selectionCriteria =
                    new google.ima.CompanionAdSelectionSettings()
                selectionCriteria.resourceType =
                    google.ima.CompanionAdSelectionSettings.ResourceType.STATIC
                selectionCriteria.creativeType =
                    google.ima.CompanionAdSelectionSettings.CreativeType.IMAGE
                selectionCriteria.sizeCriteria =
                    google.ima.CompanionAdSelectionSettings.SizeCriteria.SELECT_EXACT_MATCH
                // Get a list of companion ads for an ad slot size and CompanionAdSelectionSettings
                let playerCompanionAds = ad.getCompanionAds(
                    currentAdSettings.size
                        ? currentAdSettings?.size?.adSize?.width
                        : 300,
                    currentAdSettings.size
                        ? currentAdSettings?.size?.adSize?.height
                        : 300,
                    selectionCriteria
                )

                let miniPlayerCompanionAds = ad.getCompanionAds(
                    currentAdSettings.size
                        ? currentAdSettings?.size?.miniplayerAdSize?.width
                        : 300,
                    currentAdSettings.size
                        ? currentAdSettings?.size?.miniplayerAdSize?.height
                        : 300,
                    selectionCriteria
                )

                // Player Companions
                let playerCompanionAdImage = '/img/ad-thumb-default.svg'
                let playerCompanionAdLink = null

                // Miniplayer Companions
                let miniPlayerCompanionAdImage = '/img/ad-thumb-default.svg'
                let miniPlayerAdLink = null

                if (playerCompanionAds.length) {
                    const playerCompanionAdObj = playerCompanionAds[0]
                    const playerCompanionAdKey =
                        Object.keys(playerCompanionAdObj)[0] || null

                    playerCompanionAdImage =
                        playerCompanionAdObj[playerCompanionAdKey].resourceValue
                    playerCompanionAdLink = getClickthroughLinkFromAdContent(
                        playerCompanionAdObj.getContent()
                    )
                }

                if (miniPlayerCompanionAds.length) {
                    const miniPlayerCompanionAdObj = miniPlayerCompanionAds[0]
                    const miniPlayerCompanionAdKey =
                        Object.keys(miniPlayerCompanionAdObj)[0] || null
                    miniPlayerCompanionAdImage =
                        miniPlayerCompanionAdObj[miniPlayerCompanionAdKey]
                            .resourceValue
                    miniPlayerAdLink = getClickthroughLinkFromAdContent(
                        miniPlayerCompanionAdObj.getContent()
                    )
                }

                if (session?.token && adCount <= 1) {
                    EventTracking(
                        'ad_rendered',
                        {
                            adTitle: ad.getTitle(),
                            adType: 'pre roll',
                            adMediaType: 'audio',
                            adNumber: currentAdSettings.maxNumberAdsPlaying,
                        },
                        session
                    )
                }

                // Increment the adCount
                adCount++

                dispatch(
                    initializeCompanionAds({
                        player: {
                            thumb: playerCompanionAdImage,
                            link: playerCompanionAdLink,
                        },
                        miniPlayer: {
                            thumb: miniPlayerCompanionAdImage,
                            link: miniPlayerAdLink,
                        },
                    })
                )

                updateAdStartTime()

                adDurationCountInterval = handleAdDuration(
                    adEvent,
                    adsManager,
                    adDurationData,
                    setAdDurationData
                )

                const amplitudeEventParams = getAdTrackingEventParams(
                    ad,
                    currentVertical,
                    adStartTime,
                    playerCompanionAdLink,
                    currentPlayingCatalog,
                    duration,
                    playlist[trackCount],
                    currentAdSettings
                )

                const firebaseEventsParams = getAdTrackingEventParams(
                    ad,
                    currentVertical,
                    adStartTime,
                    playerCompanionAdLink,
                    currentPlayingCatalog,
                    duration,
                    playlist[trackCount],
                    currentAdSettings,
                    'firebase'
                )

                setFirebaseAdEventParams(firebaseEventsParams)
                setAmplitudeAdEventParams(amplitudeEventParams)

                if (session?.token) {
                    AmplitudeEventTracking(
                        'ad started',
                        amplitudeEventParams,
                        session
                    )
                    EventTracking('ad_started', firebaseEventsParams, session)
                }
            }
        )

        adsManager.addEventListener(
            google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
            (event) => {
                // reset adCount
                adCount = 1

                resumeContentAfterAd(
                    event,
                    dispatch,
                    initializeCompanionAds,
                    initializeAds,
                    setPlaying,
                    adDurationCountInterval,
                    false,
                    setUserEligibleForAds
                )
            }
        )

        adsManager.addEventListener(
            google.ima.AdEvent.Type.COMPLETE,
            (event) => {
                clearInterval(adDurationCountInterval)
                if (session?.token) {
                    AmplitudeEventTracking(
                        'ad completed',
                        amplitudeAdEventParams,
                        session
                    )
                    EventTracking(
                        'ad_completed',
                        firebaseAdEventParams,
                        session
                    )
                }
            }
        )
    }, [adsManager])

    // END: ADS

    useEffect(() => {
        return () => {
            // clear time-to-play trigger
            setTimeToPlayTriggered(false)
        }
    }, [])

    useEffect(() => {
        const firebaseAuth = localStorage.getItem('firebase-auth')
        firebaseAuth &&
            onSnapshot(collection(db, 'Content'), (snap) => {
                snap.docs.map((doc) => {
                    if (doc.id === currentPlayingCatalog?.id) {
                        dispatch(
                            setPlayingRadioListenersCount(doc.data().listeners)
                        )
                    }
                })
            })
    }, [currentPlayingCatalog?.id])

    const [rearrangedUsers, setRearrangedUsers] = useState([])

    useEffect(() => {
        const hosts = []
        const notHosts = []

        for (let index = 0; index < liveSpeakers.length; index++) {
            const currentItem = liveSpeakers[index]
            if (currentItem.type === 'host') {
                hosts.push(currentItem)
            } else if (
                currentItem?.type !== 'host' &&
                currentItem?.type !== 'listener'
            ) {
                notHosts.push(currentItem)
            }
        }

        setRearrangedUsers([...hosts, ...notHosts])
    }, [liveSpeakers])

    const currentLiveroomHosts =
        currentLiveroom?.roomParticipants?.filter((p) => p?.type === 'host') ||
        []

    const handlePlayPauseEventTracking = (action) => {
        let amplitudeEventName =
            action === 'play' ? 'listening started' : 'listening ended'
        let amplitudeEventParams = {
            vertical:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        }
        let eventName = ''
        let eventParam = {
            entitySubType:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        }
        if (pathname.includes('/catalog')) {
            eventParam.sourceType = 'catalog page'
            amplitudeEventParams.source = 'catalog page'
        }
        if (pathname.includes('/content')) {
            eventParam.sourceType = 'content page'
            amplitudeEventParams.source = 'content page'
        }
        if (pathname.includes('/playlist')) {
            eventParam.sourceType = 'playlist page'
            amplitudeEventParams.source = 'playlist page'
        }
        if (currentPlayingCatalog?.type === 'radio') {
            eventName = action === 'play' ? 'radio_played' : 'radio_stopped'
            eventParam.radioId =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id
            eventParam.radioTitle =
                playlist[trackCount]?.catalog?.title?.substring(0, 100) ||
                currentPlayingCatalog?.title?.substring(0, 100)
            eventParam.programId =
                playlist[trackCount]?.id || currentPlayingCatalog?.id
            eventParam.programTitle =
                playlist[trackCount]?.title?.substring(0, 100) ||
                currentPlayingCatalog?.title?.substring(0, 100)

            amplitudeEventParams['radio id'] =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id
            amplitudeEventParams['radio title'] =
                playlist[trackCount]?.catalog?.title ||
                currentPlayingCatalog?.title
            amplitudeEventParams['program id'] =
                playlist[trackCount]?.id || currentPlayingCatalog?.id
            amplitudeEventParams['program title'] =
                playlist[trackCount]?.title || currentPlayingCatalog?.title
        } else {
            eventName = action === 'play' ? 'content_played' : 'content_paused'

            eventParam.catalogId =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id
            eventParam.catalogTitle =
                playlist[trackCount]?.catalog?.title?.substring(0, 100) ||
                currentPlayingCatalog?.title?.substring(0, 100)
            eventParam.contentId = playlist[trackCount]?.id
            eventParam.contentTitle = playlist[trackCount]?.title?.substring(
                0,
                100
            )
            eventParam.segmentId = segmentId
            eventParam.segmentName = segmentName
            eventParam.segmentPosition = ''
            eventParam.contentFormat = videoSelected ? 'video' : 'audio'
            eventParam.videoType = videoSelected
                ? showFullScreen
                    ? 'fullscreen'
                    : toggleScreen
                    ? 'standard'
                    : 'pip'
                : null
            amplitudeEventParams['catalog id'] =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id

            amplitudeEventParams['catalog title'] =
                playlist[trackCount]?.catalog?.title ||
                currentPlayingCatalog?.title
            amplitudeEventParams['content id'] = playlist[trackCount]?.id
            amplitudeEventParams['content title'] = playlist[trackCount]?.title
            amplitudeEventParams['content duration'] =
                playlist[trackCount]?.duration
            amplitudeEventParams['genre'] = currentPlayingCatalog?.genres
                ?.length
                ? currentPlayingCatalog?.genres?.map((genre) => {
                      return genre['name']
                  })
                : []
            amplitudeEventParams['no. of contents in catalog'] =
                currentPlayingCatalog?.meta?.contentCount ||
                catalogData?.meta?.contentCount
            amplitudeEventParams['catalog source'] =
                playlist[trackCount]?.catalog?.source ||
                currentPlayingCatalog?.source
            amplitudeEventParams['stream type'] = 'streamed'
            amplitudeEventParams['created at'] = playlist[trackCount]?.createdAt
        }
        if (action === 'pause') {
            eventParam.durationPlayed =
                Math.round(playedSeconds) - Math.round(previouslyPlayedSeconds)
            amplitudeEventParams['duration played'] =
                Math.round(playedSeconds) - Math.round(previouslyPlayedSeconds)
            amplitudeEventParams['stop time'] = Math.round(playedSeconds)
        }
        if (!(currentPlayingCatalog?.type === 'radio') && action === 'play') {
            amplitudeEventParams['start time'] = Math.round(playedSeconds)
            amplitudeEventParams['start type'] = startType
            amplitudeEventParams['device category'] = isDesktop
                ? 'desktop'
                : isMobile
                ? 'mobile web'
                : deviceType
        }
        if (!(currentPlayingCatalog?.type === 'radio') && action === 'pause') {
            eventParam.elapsedTime = Math.round(playedSeconds)
            eventParam.contentDuration = playlist[trackCount]?.duration

            amplitudeEventParams['elapsed time'] = playedDurationInString
            amplitudeEventParams['content duration'] =
                playlist[trackCount]?.duration
            amplitudeEventParams['percentage listened'] = Math.round(
                (Math.round(playedSeconds) / playlist[trackCount]?.duration) *
                    100
            )
        }

        if (session?.token) {
            AmplitudeEventTracking(
                amplitudeEventName,
                amplitudeEventParams,
                session
            )
            EventTracking(eventName, eventParam, session)
        }
    }
    useEffect(() => {
        if (playlist && playlist?.length && playlist[trackCount]?.url) {
            playing
                ? handlePlayPauseEventTracking('play')
                : handlePlayPauseEventTracking('pause')
        }
    }, [playing && playlist?.length && playlist[trackCount]?.url, session])

    const playPauseContent = async (events) => {
        const data = await initializeContentPlayPauseAPI(
            { events },
            session?.token
        )
    }

    const firePlayEvent = () => {
        if (playlist && playlist?.length && playlist[trackCount]?.url) {
            let events = [
                {
                    contentId: playlist[trackCount]?.id,
                    payload: {
                        catalogId:
                            playlist[trackCount]?.catalogId ||
                            playlist[trackCount]?.catalog?.id,
                        createdAt: new Date().toISOString(),
                        entityId: playlist[trackCount]?.id,
                        entitySubType: currentPlayingCatalog?.type,
                        entityType: 'content',
                        totalDuration: playlist[trackCount]?.duration,
                        durationPlayed: !playerRef?.current?.getCurrentTime()
                            ? playlist[trackCount]?.meta?.timeElapsed
                            : playerRef?.current?.getCurrentTime() || 0,
                    },
                    userId: session?.userInfo?.id,
                    event: 'play',
                },
            ]

            playPauseContent(events)

            EventTracking(
                currentPlayingCatalog?.type === 'radio'
                    ? 'radio_played'
                    : 'content_played',
                {
                    contentId: playlist[trackCount]?.id,
                    contentTitle: playlist[trackCount]?.title,
                    catalogId:
                        playlist[trackCount]?.catalogId ||
                        playlist[trackCount]?.catalog?.id,
                    catalogTitle: playlist[trackCount]?.catalog?.title,

                    totalDuration: playlist[trackCount]?.duration,
                    durationPlayed: !playerRef?.current?.getCurrentTime()
                        ? playlist[trackCount]?.meta?.timeElapsed
                        : playerRef?.current?.getCurrentTime() || 0,
                    userId: session?.userInfo?.id,
                    trackOnPostHog: true,

                    entitySubType:
                        playlist[trackCount]?.catalog?.type ||
                        currentPlayingCatalog?.type,

                    sourceType: pathname,

                    segmentId: segmentId,
                    segmentName: segmentName,
                    segmentPosition: '',
                    contentFormat: videoSelected ? 'video' : 'audio',

                    videoType: videoSelected
                        ? showFullScreen
                            ? 'fullscreen'
                            : toggleScreen
                            ? 'standard'
                            : 'pip'
                        : null,
                },
                session
            )
        }
    }

    const firePauseEvent = () => {
        if (playlist && playlist?.length && playlist[trackCount]?.url) {
            let events = [
                {
                    contentId: playlist[trackCount]?.id,
                    payload: {
                        catalogId:
                            playlist[trackCount]?.catalogId ||
                            playlist[trackCount]?.catalog?.id,
                        createdAt: new Date().toISOString(),
                        entityId: playlist[trackCount]?.id,
                        entitySubType: currentPlayingCatalog?.type,
                        entityType: 'content',
                        totalDuration: playlist[trackCount]?.duration,
                        durationPlayed: Math.round(playedSeconds),
                        listeningTime: dayjs
                            .duration(Date.now() - startListeningTime)
                            .seconds(),
                    },
                    userId: session?.userInfo?.id,
                    event: 'pause',
                },
            ]

            playPauseContent(events)

            EventTracking(
                currentPlayingCatalog?.type === 'radio'
                    ? 'radio_paused'
                    : 'content_paused',
                {
                    contentId: playlist[trackCount]?.id,
                    contentTitle: playlist[trackCount]?.title,
                    catalogId:
                        playlist[trackCount]?.catalogId ||
                        playlist[trackCount]?.catalog?.id,
                    catalogTitle: playlist[trackCount]?.catalog?.title,

                    totalDuration: playlist[trackCount]?.duration,
                    durationPlayed: !playerRef?.current?.getCurrentTime()
                        ? playlist[trackCount]?.meta?.timeElapsed
                        : playerRef?.current?.getCurrentTime() || 0,
                    userId: session?.userInfo?.id,
                    trackOnPostHog: true,

                    entitySubType:
                        playlist[trackCount]?.catalog?.type ||
                        currentPlayingCatalog?.type,

                    sourceType: pathname,

                    segmentId: segmentId,
                    segmentName: segmentName,
                    segmentPosition: '',
                    contentFormat: videoSelected ? 'video' : 'audio',

                    videoType: videoSelected
                        ? showFullScreen
                            ? 'fullscreen'
                            : toggleScreen
                            ? 'standard'
                            : 'pip'
                        : null,
                },
                session
            )
        }
    }

    useEffect(() => {
        if (joinedLiveroom) {
            // onSnapshot(collection(db, 'Room'), (snap) => {
            //     snap.docs.map((doc) => {
            //         if (doc.id === currentLiveroom?.id) {
            //             dispatch(
            //                 setCurrentLiveroomParticipantCount(
            //                     doc.data().participantCount
            //                 )
            //             )
            //         }
            //     })
            // })
            // const liveSpeakersColl = query(
            //     collection(db, 'Room', currentLiveroom?.id, 'liveSpeakers')
            // )
            // onSnapshot(liveSpeakersColl, (querySnapshot) => {
            //     dispatch(
            //         setCurrentLiveSpeakers(
            //             querySnapshot.docs.map((speaker) => speaker.data())
            //         )
            //     )
            // })
        }
    }, [joinedLiveroom])

    useEffect(() => {
        const handleRouteChange = () => {
            if (!autoPlayed && isReady) {
                dispatch(setToggleScreen(false))

                if (showFullScreen) {
                    setShowFullScreen(false)
                    fscreen.exitFullscreen()
                }
            }
        }

        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events, showFullScreen])

    useEffect(() => {
        const hidePlayeronRouteChange = () => {
            if (!pathname?.includes('/content/')) {
                dispatch(setToggleScreen(false))

                if (showFullScreen) {
                    setShowFullScreen(false)
                    fscreen.exitFullscreen()
                }
            }
        }

        router.events.on('routeChangeComplete', hidePlayeronRouteChange)
        return () => {
            router.events.off('routeChangeComplete', hidePlayeronRouteChange)
        }
    }, [pathname])

    const handleEndedEventTracking = () => {
        let eventName = 'content_listened'
        let eventParam = {
            entitySubType:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        }
        if (pathname.includes('/catalog')) {
            eventParam.sourceType = 'catalog page'
        }
        if (pathname.includes('/content')) {
            eventParam.sourceType = 'content page'
        }
        if (pathname.includes('/playlist')) {
            eventParam.sourceType = 'playlist page'
        }

        if (currentPlayingCatalog?.type === 'radio') {
            eventParam.radioId =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id
            eventParam.radioTitle =
                playlist[trackCount]?.catalog?.title ||
                currentPlayingCatalog?.title
            eventParam.programId =
                playlist[trackCount]?.id || currentPlayingCatalog?.id
            eventParam.programTitle =
                playlist[trackCount]?.title || currentPlayingCatalog?.title
        } else {
            eventParam.catalogId =
                playlist[trackCount]?.catalog?.id || currentPlayingCatalog?.id
            eventParam.catalogTitle =
                playlist[trackCount]?.catalog?.title ||
                currentPlayingCatalog?.title
            eventParam.contentId = playlist[trackCount]?.id
            eventParam.contentTitle = playlist[trackCount]?.title
        }
        eventParam.durationPlayed =
            Math.round(playedSeconds) - Math.round(previouslyPlayedSeconds)
        if (!(currentPlayingCatalog?.type === 'radio')) {
            eventParam.elapsedTime = Math.round(playedSeconds)
            eventParam.contentDuration = playlist[trackCount]?.duration
        }

        EventTracking(eventName, eventParam, session)
    }

    const updateStartTime = () => {
        startTime = Date.now()
        if (toggleScreen) {
            dispatch(setToggleScreen(true))
        }

        const video = document.querySelector('video')
        if (!isVideo) return

        const handleLoaded = () => {
            if (!toggleScreen && playing && isVideo) {
                video.requestPictureInPicture()

                const handleLeavePip = (e) => {
                    const timeout = setTimeout(() => {
                        const currentPlayingContent = playlist?.find(
                            (singlePlaylist) =>
                                singlePlaylist?.id === currentTrackId
                        )

                        if (
                            !video.paused &&
                            playerRef.current.props.url ===
                                currentPlayingContent?.videoUrl
                        ) {
                            dispatch(setToggleScreen(true))
                        }

                        clearTimeout(timeout)

                        video.removeEventListener(
                            'leavepictureinpicture',
                            handleLeavePip
                        )
                    }, 10)
                }

                video.addEventListener('leavepictureinpicture', handleLeavePip)
            }

            video.removeEventListener('loadedmetadata', handleLoaded)
        }

        if (isMobile) {
            dispatch(setToggleScreen(true))
        } else {
            video.addEventListener('loadedmetadata', handleLoaded)
        }
    }

    const updateAdStartTime = () => {
        adStartTime = Date.now()
    }

    const handlePlay = () => {
        firePlayEvent()

        setStartListeningTime(Date.now())
        const oldUsersListenData = getOldUsersListenData()
        const {
            prerollEnabled,
            midrollEnabled,
            contentListenedCount,
            totalMinutesListened,
        } = adsSettings[currentVertical]
        let { secondsListened, lastListened, contentPlayed, currentPlaytime } =
            oldUsersListenData[currentVertical]

        const totalListened = secondsListened + currentPlaytime
        const sameTrack =
            lastListened === currentTrackId ||
            lastListened === currentPlayingCatalog.id

        if (
            !ads?.adError &&
            adsSettings[currentVertical] &&
            adsEnabled &&
            !sameTrack &&
            !isIOS &&
            (prerollEnabled || midrollEnabled) &&
            imaConfig.adsDisplayContainer
        ) {
            if (
                contentPlayed >= contentListenedCount &&
                Math.floor(totalListened / 60) >= totalMinutesListened
            ) {
                setUserEligibleForAds(true)
                dispatch(setPlaying(false))
                return
            }
        } else {
            setUserEligibleForAds(false)
            dispatch(initializeAds({ adError: false }))
        }

        setUsersListenData(oldUsersListenData, {
            [currentVertical]: {
                ...oldUsersListenData[currentVertical],
                contentPlayed: !sameTrack ? contentPlayed + 1 : contentPlayed,
                secondsListened: totalListened,
                currentPlaytime: 0,
                lastListened: currentTrackId || currentPlayingCatalog.id,
            },
        })

        // Track time-to-play event
        const playTime = Date.now()

        const diff = playTime - startTime

        const timeToPlay = diff / 1000

        !timeToPlayTriggered &&
            TRIGGER_TIME_TO_PLAY(timeToPlay, playlist[trackCount])
        setTimeToPlayTriggered(true)

        if (currentVertical === 'radio') return

        setPreviouslyPlayedSeconds(playedSeconds)
        setStartType('manual')
        dispatch(setPlaying(true))

        // playerRef.current.seekTo(playlist[trackCount]?.meta?.timeElapsed)
        const currentPlayingContent = playlist?.find(
            (singlePlaylist) => singlePlaylist?.id === currentTrackId
        )

        if (timeElapsed > 0 && timeElapsed < playlist[trackCount]?.duration) {
            playerRef.current.seekTo(timeElapsed)
            setPlayedSeconds(timeElapsed)
            setTimeElapsed(0)
        } else if (
            Math.ceil(playlist[trackCount]?.meta?.timeElapsed) >=
            playlist[trackCount]?.duration
        ) {
            playerRef.current.seekTo(0)
        } else {
            if (!playerRef?.current?.getCurrentTime()) {
                playerRef.current.seekTo(
                    playlist[trackCount]?.meta?.timeElapsed
                )
                setPlayedSeconds(playlist[trackCount]?.meta?.timeElapsed)
            }
        }
    }

    const handlePause = () => {
        const oldUsersListenData = getOldUsersListenData()
        setUsersListenData(oldUsersListenData, {
            [currentVertical]: {
                ...oldUsersListenData[currentVertical],
                secondsListened:
                    oldUsersListenData[currentVertical].secondsListened +
                    oldUsersListenData[currentVertical].currentPlaytime,
                currentPlaytime: 0,
            },
        })

        if (currentVertical !== 'radio') {
            //playlist update
            dispatch(
                setElapsedTime({
                    id: currentTrackId,
                    timeElapsed: playedSeconds,
                })
            )
            //segment update
            dispatch(
                updateElapsedTime({
                    id: currentTrackId,
                    timeElapsed: playedSeconds,
                })
            )

            //collection update
            dispatch(
                updateCollectionElapsedTime({
                    id: currentTrackId,
                    timeElapsed: playedSeconds,
                })
            )

            //content update
            dispatch(
                updateContentElapsedTime({
                    id: currentTrackId,
                    timeElapsed: playedSeconds,
                })
            )
        }

        dispatch(setPlaying(false))

        firePauseEvent()
    }

    const handleSeekMouseDown = (e) => {
        e.stopPropagation()
        setSeeking(true)
    }

    const handleSeekChange = (e) => {
        e.stopPropagation()
        setPlayed(parseFloat(e.target.value))
    }

    const handleSeekMouseUp = (e) => {
        e.stopPropagation()
        setSeeking(false)
        playerRef.current.seekTo(parseFloat(e.target.value))
        EventTracking('slider_adjusted', {
            timestampPosition: !playerRef?.current?.getCurrentTime()
                ? playlist[trackCount]?.meta?.timeElapsed
                : playerRef?.current?.getCurrentTime() || 0,
            catalogTitle: currentPlayingCatalog?.title,
            catalogId: currentPlayingCatalog?.id,
            contentTitle: playlist[trackCount]?.title,
            contentId: playlist[trackCount]?.id,
            entitySubType:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        })
    }

    const handleProgress = (state) => {
        if (seeking) return

        // Get OLD usersListenData from local storage
        const oldUsersListenData = getOldUsersListenData()

        setPlayed(state.played)
        setPlayedSeconds(state.playedSeconds)
        const playedTimeInMinutes = Math.floor(state.playedSeconds / 60)
        const playedTimeInMinutesInString =
            playedTimeInMinutes < 10
                ? `0${playedTimeInMinutes}`
                : `${playedTimeInMinutes}`
        const playedTimeInSeconds = Math.round(state.playedSeconds) % 60

        // Set currentPLayTime of usersListenData to local storage
        if (playing && (state.played || currentVertical === 'radio')) {
            setUsersListenData(oldUsersListenData, {
                [currentVertical]: {
                    ...oldUsersListenData[currentVertical],
                    currentPlaytime:
                        oldUsersListenData[currentVertical].currentPlaytime + 1,
                },
            })
        }

        if (currentVertical === 'radio') return

        const playedTimeInSecondsInString =
            playedTimeInSeconds < 10
                ? `0${playedTimeInSeconds}`
                : `${playedTimeInSeconds}`
        const leftDurationInMinutes = Math.floor(
            (duration - state.playedSeconds) / 60
        )
        const leftDurationInMinutesInString =
            leftDurationInMinutes < 10
                ? `0${leftDurationInMinutes}`
                : `${leftDurationInMinutes}`
        const leftDurationInSeconds =
            Math.round(duration - state.playedSeconds) % 60
        const leftDurationInSecondsInString =
            leftDurationInSeconds < 10
                ? `0${leftDurationInSeconds}`
                : `${leftDurationInSeconds}`

        setPlayedDurationInString(
            `${playedTimeInMinutesInString}:${playedTimeInSecondsInString}`
        )
    }

    const handleEnded = () => {
        const oldUsersListenData = getOldUsersListenData()
        setUsersListenData(oldUsersListenData, {
            [currentVertical]: {
                ...oldUsersListenData[currentVertical],
                currentPlaytime: 0,
            },
        })

        // Reset Ads for next playthrough
        if (adsManager) {
            adsManager.destroy()
        }

        setAdsManager(null)
        setUserEligibleForAds(false)

        let events = [
            {
                contentId: playlist[trackCount]?.id,
                event: 'pause',
                payload: {
                    entityId: playlist[trackCount]?.id,
                    entityType: 'content',
                    durationPlayed: playlist[trackCount]?.duration,
                    listeningTime: dayjs.duration(
                        Date.now() - startListeningTime
                    ),
                },
            },
        ]
        playPauseContent(events)

        if (currentVertical !== 'radio') {
            //playlist update
            dispatch(
                setElapsedTime({
                    id: playlist[trackCount]?.id,
                    timeElapsed: playlist[trackCount]?.duration,
                })
            )
            //segment update
            dispatch(
                updateElapsedTime({
                    id: playlist[trackCount]?.id,
                    timeElapsed: playlist[trackCount]?.duration,
                })
            )

            //collection update
            dispatch(
                updateCollectionElapsedTime({
                    id: playlist[trackCount]?.id,
                    timeElapsed: playlist[trackCount]?.duration,
                })
            )

            //content update
            dispatch(
                updateContentElapsedTime({
                    id: playlist[trackCount]?.id,
                    timeElapsed: playlist[trackCount]?.duration,
                })
            )
        }
        if (playlist[trackCount + 1]) {
            setPlayedSeconds(0)

            setStartType('autoplay')
            playlist[trackCount + 1]?.catalog &&
                dispatch(
                    setCurrentPlayingCatalog(playlist[trackCount + 1]?.catalog)
                )
            dispatch(setTrackCount(trackCount + 1))
            dispatch(setCurrentTrackId(playlist[trackCount + 1]?.id))
            dispatch(setPlaying(true))
        } else {
            dispatch(setPlaying(false))
        }
        handleEndedEventTracking()
    }

    const handleDuration = (duration) => {
        setDuration(duration)
    }

    const handleRewind = () => {
        playerRef.current.seekTo(playedSeconds - 10)
        EventTracking('rewind_10s', {
            timestampPosition: !playerRef?.current?.getCurrentTime()
                ? playlist[trackCount]?.meta?.timeElapsed
                : playerRef?.current?.getCurrentTime() || 0,
            catalogTitle: currentPlayingCatalog?.title,
            catalogId: currentPlayingCatalog?.id,
            contentTitle: playlist[trackCount]?.title,
            contentId: playlist[trackCount]?.id,
            entitySubType:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        })
    }

    const handleFastForward = () => {
        playerRef.current.seekTo(playedSeconds + 10)
        EventTracking('skip_10s', {
            timestampPosition: !playerRef?.current?.getCurrentTime()
                ? playlist[trackCount]?.meta?.timeElapsed
                : playerRef?.current?.getCurrentTime() || 0,
            catalogTitle: currentPlayingCatalog?.title,
            catalogId: currentPlayingCatalog?.id,
            contentTitle: playlist[trackCount]?.title,
            contentId: playlist[trackCount]?.id,
            entitySubType:
                playlist[trackCount]?.catalog?.type ||
                currentPlayingCatalog?.type,
        })
    }

    const checkIfURLIsStaticOrNot = (url) => {
        let urlSubstring = url.substring(url.indexOf(':') + 1)
        if (urlSubstring.length === 4 || urlSubstring.includes('.pls')) {
            return 'staticWithoutSlash'
        } else if (urlSubstring.charAt(urlSubstring.length - 1) === '/') {
            return 'staticWithSlash'
        }

        return 'dynamic'
    }

    const manipulatePlayableURL = (data) => {
        if (data && data?.length && data[trackCount]?.url) {
            if (
                currentPlayingCatalog?.type === 'radio' &&
                data[trackCount]?.url.indexOf('http://') === 0
            ) {
                let actualURL = data[trackCount]?.url

                let urlWithoutHTTP = actualURL.replace('http://', '')

                if (
                    checkIfURLIsStaticOrNot(urlWithoutHTTP) ===
                    'staticWithoutSlash'
                ) {
                    return `${process.env.NEXT_PUBLIC_RADIO_PROXY_V2}?${actualURL}/;`
                } else if (
                    checkIfURLIsStaticOrNot(urlWithoutHTTP) ===
                    'staticWithSlash'
                ) {
                    return `${process.env.NEXT_PUBLIC_RADIO_PROXY_V2}?${actualURL};`
                } else {
                    // urlWithoutHTTP = urlWithoutHTTP.replace(':', '/')
                    // return `${process.env.NEXT_PUBLIC_NOICE_BASE_URL}proxy/radio/${urlWithoutHTTP}`

                    return `${process.env.NEXT_PUBLIC_RADIO_PROXY_V1}/${urlWithoutHTTP}`
                }
            } else {
                const url = data[trackCount]?.videoUrl || data[trackCount]?.url
                return url
            }
        }
    }

    const isRadio = currentPlayingCatalog?.type === 'radio'
    const showingAd = ads.showingAd
    const isVideo =
        playlist &&
        !!playlist[trackCount]?.videoUrl &&
        videoSelected &&
        !showingAd

    const isVideoContent = playlist && !!playlist[trackCount]?.videoUrl

    useEffect(() => {
        if (isVideoContent) {
            setVideoSelected(true)
        }
    }, [isVideoContent])

    useEffect(() => {
        setPreviouslyPlayedSeconds(playedSeconds)
        const durationPlayed =
            Math.round(playedSeconds) - Math.round(previouslyPlayedSeconds)
        if (videoSelected) {
            EventTracking('content_type_switched', {
                contentId: playlist[trackCount]?.id,
                contentTitle: playlist[trackCount]?.title,
                catalogId: currentPlayingCatalog?.id,
                catalogTitle: currentPlayingCatalog?.title,
                timestampPosition: !playerRef?.current?.getCurrentTime()
                    ? playlist[trackCount]?.meta?.timeElapsed
                    : playerRef?.current?.getCurrentTime() || 0,
                durationPlayed: durationPlayed,
                vertical:
                    playlist[trackCount]?.catalog?.type ||
                    currentPlayingCatalog?.type,
                switchFrom: 'audio',
                switchTo: 'video',
                videoType: null,
            })
        } else {
            EventTracking('content_type_switched', {
                contentId: playlist[trackCount]?.id,
                contentTitle: playlist[trackCount]?.title,
                catalogId: currentPlayingCatalog?.id,
                catalogTitle: currentPlayingCatalog?.title,
                timestampPosition: !playerRef?.current?.getCurrentTime()
                    ? playlist[trackCount]?.meta?.timeElapsed
                    : playerRef?.current?.getCurrentTime() || 0,
                durationPlayed: durationPlayed,
                vertical:
                    playlist[trackCount]?.catalog?.type ||
                    currentPlayingCatalog?.type,
                switchFrom: 'video',
                switchTo: 'audio',
                videoType: showFullScreen
                    ? 'fullscreen'
                    : toggleScreen
                    ? 'standard'
                    : 'pip',
            })
        }

        setPreviousTimeStamp(
            !playerRef?.current?.getCurrentTime()
                ? playlist[trackCount]?.meta?.timeElapsed
                : playerRef?.current?.getCurrentTime() || 0
        )
    }, [videoSelected])

    useEffect(() => {
        if (showFullScreen && !isVideo) {
            setShowFullScreen(false)
            fscreen.exitFullscreen()
        }
    }, [showFullScreen, isVideo])

    useEffect(() => {
        if (!isVideo && document.pictureInPictureElement) {
            document.exitPictureInPicture()
        }
    }, [isVideo])

    useEffect(() => {
        if (showingAd) {
            adsManager?.setVolume(soundValue / 100)
        }
    }, [soundValue, showingAd])

    const getVideoSyle = () => {
        if (!isVideo) {
            return {
                width: '0px',
                height: '0px',
            }
        }

        if (showFullScreen)
            return {
                width: '100vw',
                height: '100%',
                maxHeight: '100%',
                background: 'black',
                position: 'absolute',
                top: '0px',
                zIndex: 999,
            }
    }

    const currentPlayingContent = playlist?.find(
        (singlePlaylist) => singlePlaylist?.id === currentTrackId
    )

    const currentPlayingUrl =
        currentPlayingContent?.videoUrl || currentPlayingContent?.url

    useEffect(() => {
        if (!currentPlayingUrl) playNext({ stopPropagation: () => {} })
    }, [currentPlayingUrl])

    const currentPlayingContentIndex = playlist?.findIndex(
        (singlePlaylist) => singlePlaylist?.id === currentTrackId
    )
    const previousContentNotAvailable = currentPlayingContentIndex < 1

    const nextContentNotAvailable =
        currentPlayingContentIndex > playlist?.length - 2

    //time formatter

    const currentTime = playerRef.current
        ? playerRef.current.getCurrentTime()
        : '00:00'

    const totalContentDuration = playerRef.current
        ? playerRef.current.getDuration()
        : '00:00'

    const formatPlayerTime = (time) => {
        //formarting duration of content
        if (isNaN(time)) {
            return '00:00'
        }

        const date = new Date(time * 1000)
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes()
        const seconds = date.getUTCSeconds().toString().padStart(2, '0')
        if (hours) {
            //if content have hours
            return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`
        } else return `${minutes}:${seconds}`
    }

    const formatCurrentTime = formatPlayerTime(currentTime)

    const formatDuration = formatPlayerTime(totalContentDuration)

    const play = (e) => {
        e.stopPropagation()

        if (ads.showingAd && ads.adPlaying) {
            adsManager.pause()
            dispatch(
                initializeAds({
                    adPlaying: false,
                })
            )
        } else if (ads.showingAd && !ads.adPlaying) {
            adsManager.resume()

            dispatch(
                initializeAds({
                    adPlaying: true,
                })
            )
        } else {
            dispatch(setPlaying(!playing))
            {
                joinedLiveroom && leave(pubnub)
            }
            currentPlayingCatalog?.type === 'radio' &&
                radioListenerCountHandler(
                    !playing ? 1 : -1,
                    currentPlayingCatalog?.id
                )
        }
    }

    const playNext = (e) => {
        e.stopPropagation()

        if (playlist[trackCount + 1]) {
            setPlayedSeconds(0)

            setStartType('autoplay')
            playlist[trackCount + 1]?.catalog &&
                dispatch(
                    setCurrentPlayingCatalog(playlist[trackCount + 1]?.catalog)
                )
            dispatch(setTrackCount(trackCount + 1))
            dispatch(setCurrentTrackId(playlist[trackCount + 1]?.id))
            dispatch(setPlaying(true))
        }
    }

    const playPrevious = (e) => {
        e.stopPropagation()

        if (playlist[trackCount - 1]) {
            setPlayedSeconds(0)

            setStartType('autoplay')
            playlist[trackCount - 1]?.catalog &&
                dispatch(
                    setCurrentPlayingCatalog(playlist[trackCount + 1]?.catalog)
                )
            dispatch(setTrackCount(trackCount - 1))
            dispatch(setCurrentTrackId(playlist[trackCount - 1]?.id))
            dispatch(setPlaying(true))
        }
    }
    useEffect(() => {
        if (
            adsSettings &&
            currentVertical &&
            adsSettings[currentVertical] &&
            playerRef?.current
        ) {
            setIsReady(true)
        }
    }, [adsSettings, currentVertical])

    const handleScreenClick = (e) => {
        if (soundValue === 0) {
            const clickedElementId = e.target.id
            if (
                clickedElementId !== 'ele-1' &&
                clickedElementId !== 'ele-2' &&
                clickedElementId !== 'ele-3' &&
                clickedElementId !== 'ele-4' &&
                clickedElementId !== 'ele-5' &&
                clickedElementId !== 'ele-6' &&
                clickedElementId !== 'ele-7' &&
                clickedElementId !== 'ele-8' &&
                clickedElementId !== 'ele-9' &&
                clickedElementId !== 'ele-10'
            ) {
                dispatch(setSoundValue(50))
            }
        }
    }

    useEffect(() => {
        if (
            !pathname?.includes('/content/') &&
            !pathname?.includes('/catalog/') &&
            soundValue === 0 &&
            !isReady
        ) {
            dispatch(setSoundValue(50))
        }
    }, [pathname])

    useEffect(() => {
        dispatch(setAutoPlayed(true))
    }, [])

    if (
        catalogData?.type ||
        (playlist && playlist?.length && playlist[trackCount]?.url) ||
        joinedLiveroom
    )
        return (
            <>
                <>
                    <Container
                        onClick={() => dispatch(setToggleScreen(true))}
                        showPlayer={showPlayer}
                        mouseMoved={mouseMoved}
                        showFullScreen={showFullScreen}
                        isNoicebook={isNoicebook}
                    >
                        {!(currentPlayingCatalog?.type === 'radio') &&
                            !joinedLiveroom && (
                                <div
                                    style={{
                                        width: '100%',
                                        position: 'absolute',
                                        top: '0px',
                                        left: '0px',
                                        height: '4px',
                                        display: 'flex',
                                    }}
                                    className="slider-container"
                                >
                                    {ads.showingAd ? (
                                        <Slider
                                            type="range"
                                            min={0}
                                            max={0.999999}
                                            step="any"
                                            value={adDurationData.playedSeconds}
                                            className="slider"
                                            readOnly={true}
                                            style={{
                                                backgroundSize: `${
                                                    adDurationData.playedSeconds *
                                                    100
                                                }% 100%`,
                                            }}
                                        />
                                    ) : (
                                        <Slider
                                            type="range"
                                            min={0}
                                            max={0.999999}
                                            step="any"
                                            value={played}
                                            className="slider"
                                            readOnly={true}
                                            style={{
                                                backgroundSize: `${
                                                    played * 100
                                                }% 100%`,
                                            }}
                                            onTouchStart={handleSeekMouseDown}
                                            onChange={handleSeekChange}
                                            onTouchEnd={handleSeekMouseUp}
                                            onMouseDown={handleSeekMouseDown}
                                            onMouseUp={handleSeekMouseUp}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    )}
                                </div>
                            )}

                        <PlayerInformation>
                            <PlayPauseContainer>
                                {!isRadio && !showingAd && (
                                    <PlaybackRateMenu
                                        setPlaybackRate={setPlaybackRate}
                                        playbackRate={playbackrRate}
                                    />
                                )}

                                {!isRadio && !showingAd && (
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleRewind()
                                        }}
                                    >
                                        <TooltipContainer title="Mundur 10 detik">
                                            <img src="/img/backward_grey.svg" />
                                        </TooltipContainer>
                                    </IconButton>
                                )}

                                <IconButton
                                    onClick={playPrevious}
                                    disabled={
                                        previousContentNotAvailable || showingAd
                                    }
                                    style={{
                                        opacity:
                                            previousContentNotAvailable ||
                                            showingAd
                                                ? 0.2
                                                : 1,
                                    }}
                                >
                                    <TooltipContainer title="Konten Sebelumnya">
                                        <img src="/img/previous_grey.svg" />
                                    </TooltipContainer>
                                </IconButton>

                                {isRadio ? (
                                    <IconButton
                                        className="ripple-button ripple"
                                        onClick={play}
                                    >
                                        <img
                                            src={
                                                playing || ads.adPlaying
                                                    ? '/img/stop_fill.svg'
                                                    : '/img/play_outline.svg'
                                            }
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        className="ripple-button ripple"
                                        onClick={play}
                                    >
                                        <img
                                            src={
                                                playing || ads.adPlaying
                                                    ? '/img/pause_outline.svg'
                                                    : '/img/play_outline.svg'
                                            }
                                        />
                                    </IconButton>
                                )}

                                <IconButton
                                    onClick={playNext}
                                    disabled={
                                        nextContentNotAvailable || showingAd
                                    }
                                    style={{
                                        opacity:
                                            nextContentNotAvailable || showingAd
                                                ? 0.2
                                                : 1,
                                    }}
                                >
                                    <TooltipContainer title="Konten Selanjutnya">
                                        <img src="/img/next_grey.svg" />
                                    </TooltipContainer>
                                </IconButton>

                                {!isRadio && !showingAd && (
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleFastForward()
                                        }}
                                    >
                                        <TooltipContainer title="Maju 10 detik">
                                            <img src="/img/forward_grey.svg" />
                                        </TooltipContainer>
                                    </IconButton>
                                )}

                                {!isRadio && (
                                    <span>
                                        {formatCurrentTime} / {formatDuration}
                                    </span>
                                )}

                                {isRadio && (
                                    <img
                                        src="/img/live_radio.svg"
                                        alt="live radio icon"
                                    />
                                )}
                            </PlayPauseContainer>

                            <ImageTitleContainer>
                                {!joinedLiveroom ? (
                                    <>
                                        <ContentImage
                                            loading="lazy"
                                            src={
                                                companionAds.miniPlayer.thumb
                                                    ? companionAds.miniPlayer
                                                          .thumb
                                                    : compressedImage(
                                                          playlist?.length &&
                                                              playlist[
                                                                  trackCount
                                                              ]?.image,
                                                          playlist?.length &&
                                                              playlist[
                                                                  trackCount
                                                              ]?.imageMeta
                                                      ) ||
                                                      compressedImage(
                                                          playlist?.length &&
                                                              currentPlayingCatalog?.image,
                                                          playlist?.length &&
                                                              currentPlayingCatalog?.imageMeta
                                                      ) ||
                                                      compressedImage(
                                                          catalogData?.image,
                                                          catalogData?.imageMeta
                                                      )
                                            }
                                        />
                                    </>
                                ) : (
                                    <ContentImage
                                        loading="lazy"
                                        src={
                                            joinedLiveroom &&
                                            compressedUserPhoto(
                                                currentLiveroomHosts[0]?.user
                                            )
                                        }
                                    />
                                )}

                                <ContentTitleAndCatalogueNameContainer>
                                    <div className="title_wrapper">
                                        {!joinedLiveroom ? (
                                            <>
                                                {' '}
                                                <Title>
                                                    {ads.showingAd ? (
                                                        'Iklan'
                                                    ) : (
                                                        <>
                                                            {playlist?.length &&
                                                                playlist[
                                                                    trackCount
                                                                ]?.title}{' '}
                                                        </>
                                                    )}
                                                </Title>
                                                {!ads.showingAd && (
                                                    <CatalogueTitle
                                                        role="link"
                                                        onClick={(e) => {
                                                            e.stopPropagation()

                                                            if (isMobile) {
                                                                dispatch(
                                                                    setToggleScreen(
                                                                        true
                                                                    )
                                                                )
                                                                return
                                                            }

                                                            const catalogId =
                                                                currentPlayingCatalog?.id ||
                                                                catalogData?.id

                                                            router.push(
                                                                `/catalog/${catalogId}`
                                                            )
                                                        }}
                                                    >
                                                        {currentPlayingCatalog
                                                            ? currentPlayingCatalog?.title
                                                            : catalogData?.title}
                                                    </CatalogueTitle>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Title>{`@${currentLiveroomHosts[0]?.user?.userName}`}</Title>
                                                <CatalogueTitle>
                                                    {currentLiveroom?.title}
                                                </CatalogueTitle>
                                            </>
                                        )}
                                    </div>
                                    {!showingAd && (
                                        <DesktopOnly
                                            style={{ marginTop: '2px' }}
                                        >
                                            <ThreeDotMenu
                                                currentCatalog={
                                                    currentPlayingCatalog ||
                                                    catalogData
                                                }
                                                currentContent={playlist?.find(
                                                    (singlePlaylist) =>
                                                        singlePlaylist?.id ===
                                                        currentTrackId
                                                )}
                                                isRadio={isRadio}
                                            />
                                        </DesktopOnly>
                                    )}

                                    {showingAd && companionAds.player?.link && (
                                        <AdButtonOnMiniplayer
                                            href={companionAds.player.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => {
                                                if (session?.token) {
                                                    AmplitudeEventTracking(
                                                        'ad clicked',
                                                        amplitudeAdEventParams,
                                                        session
                                                    )
                                                    EventTracking(
                                                        'ad_clicked',
                                                        firebaseAdEventParams,
                                                        session
                                                    )
                                                }
                                            }}
                                        >
                                            Kunjungi Iklan
                                        </AdButtonOnMiniplayer>
                                    )}
                                </ContentTitleAndCatalogueNameContainer>
                            </ImageTitleContainer>

                            {isRadio && (
                                <DesktopOnly>
                                    <RadioFavorite
                                        role="button"
                                        onClick={async (e) => {
                                            e.stopPropagation()

                                            if (!isLoggedin) {
                                                dispatch(
                                                    setShowLoginPrompt(true)
                                                )
                                                return
                                            }

                                            const catalogId =
                                                currentPlayingCatalog?.id

                                            await subscribeToCatalog({
                                                catalogId,
                                                userId,
                                                token,
                                                subscribed,
                                            })

                                            await mutate()
                                        }}
                                    >
                                        <img
                                            src={
                                                subscribed
                                                    ? '/img/love_fill.svg'
                                                    : '/img/love_outline.svg'
                                            }
                                            alt="favorite"
                                        />

                                        <span>
                                            {NumberConversion(followers)}
                                        </span>
                                    </RadioFavorite>
                                </DesktopOnly>
                            )}

                            {!isRadio && !showingAd && (
                                <MobileOnly>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleRewind()
                                        }}
                                    >
                                        <img src="/img/backward.svg" />
                                    </IconButton>
                                </MobileOnly>
                            )}

                            <PlayIconContainer>
                                <IconButton
                                    className="ripple-button ripple"
                                    onClick={(e) => {
                                        e.stopPropagation()

                                        if (ads.showingAd && ads.adPlaying) {
                                            adsManager.pause()
                                            dispatch(
                                                initializeAds({
                                                    adPlaying: false,
                                                })
                                            )
                                        } else if (
                                            ads.showingAd &&
                                            !ads.adPlaying
                                        ) {
                                            adsManager.resume()

                                            dispatch(
                                                initializeAds({
                                                    adPlaying: true,
                                                })
                                            )
                                        } else {
                                            dispatch(setPlaying(!playing))
                                            {
                                                joinedLiveroom && leave(pubnub)
                                            }
                                            currentPlayingCatalog?.type ===
                                                'radio' &&
                                                radioListenerCountHandler(
                                                    !playing ? 1 : -1,
                                                    currentPlayingCatalog?.id
                                                )
                                        }
                                    }}
                                >
                                    <PlayIcon
                                        loading="lazy"
                                        src={
                                            playing || ads.adPlaying
                                                ? !(
                                                      currentPlayingCatalog?.type ===
                                                      'radio'
                                                  )
                                                    ? !joinedLiveroom
                                                        ? '/img/pause_mobile.svg'
                                                        : '/img/stop_mobile.svg'
                                                    : '/img/stop_mobile.svg'
                                                : '/img/play_mobile.svg'
                                        }
                                    />
                                </IconButton>
                            </PlayIconContainer>

                            <SoundSliderContainer
                                onClick={(e) => e.stopPropagation()}
                            >
                                <IconButton
                                    onClick={() => {
                                        if (soundValue !== 0)
                                            dispatch(setSoundValue(0))
                                        else dispatch(setSoundValue(50))
                                    }}
                                >
                                    <TooltipContainer
                                        title={
                                            soundValue !== 0 ? 'Bisukan' : ''
                                        }
                                    >
                                        <img
                                            src={
                                                soundValue === 0
                                                    ? '/img/muted.svg'
                                                    : '/img/volume_grey.svg'
                                            }
                                            alt="volume"
                                        />
                                    </TooltipContainer>
                                </IconButton>

                                <SoundSlider
                                    value={soundValue}
                                    onChange={(value) => {
                                        dispatch(setSoundValue(value))
                                    }}
                                    railStyle={{
                                        background: '#949494',
                                    }}
                                    trackStyle={{
                                        background: '#FFF',
                                    }}
                                    handleStyle={{
                                        background: 'white',
                                        borderRadius: '50%',
                                    }}
                                    style={{
                                        width: '100%',
                                    }}
                                />

                                <IconButton
                                    onClick={() => {
                                        if (showFullScreen) {
                                            setShowFullScreen(false)
                                            fscreen.exitFullscreen()

                                            dispatch(
                                                setToggleScreen(!toggleScreen)
                                            )
                                        } else
                                            dispatch(
                                                setToggleScreen(!toggleScreen)
                                            )
                                    }}
                                >
                                    <TooltipContainer
                                        title={
                                            toggleScreen
                                                ? 'Kecilkan'
                                                : 'Lebarkan'
                                        }
                                    >
                                        <img
                                            src="/img/carret_down_grey.svg"
                                            alt="down"
                                            style={{
                                                transform: toggleScreen
                                                    ? 'rotate(0deg)'
                                                    : 'rotate(180deg)',
                                            }}
                                        />
                                    </TooltipContainer>
                                </IconButton>
                            </SoundSliderContainer>

                            {showFullScreen && (
                                <IconButton
                                    onClick={() => {
                                        setShowFullScreen(false)
                                        fscreen.exitFullscreen()
                                        setPreviouslyPlayedSeconds(
                                            playedSeconds
                                        )
                                        const durationPlayed =
                                            Math.round(playedSeconds) -
                                            Math.round(previouslyPlayedSeconds)
                                        EventTracking('video_format_swithced', {
                                            contentId: playlist[trackCount]?.id,
                                            contentTitle:
                                                playlist[trackCount]?.title,
                                            catalogId:
                                                currentPlayingCatalog?.id,
                                            catalogTitle:
                                                currentPlayingCatalog?.title,
                                            switchFrom: 'fullscreen',
                                            switchTo: 'standard',
                                            durationPlayed: durationPlayed,
                                            timestampPosition:
                                                Math.round(playedSeconds),
                                            videoType: showFullScreen
                                                ? 'fullscreen'
                                                : toggleScreen
                                                ? 'standard'
                                                : 'pip',
                                        })
                                    }}
                                >
                                    <img
                                        src="/img/exit_full_screen.svg"
                                        alt="exit full screen"
                                    />
                                </IconButton>
                            )}
                        </PlayerInformation>

                        <MobileSliderContainer>
                            {!(currentPlayingCatalog?.type === 'radio') &&
                                !joinedLiveroom && (
                                    <>
                                        {ads.showingAd ? (
                                            <MobileSlider
                                                type="range"
                                                min={0}
                                                max={0.999999}
                                                step="any"
                                                value={
                                                    adDurationData.playedSeconds
                                                }
                                                className="slider"
                                                readOnly={true}
                                                style={{
                                                    backgroundSize: `${
                                                        adDurationData.playedSeconds *
                                                        100
                                                    }% 100%`,
                                                }}
                                            />
                                        ) : (
                                            <MobileSlider
                                                type="range"
                                                min={0}
                                                max={0.999999}
                                                step="any"
                                                value={played}
                                                className="slider mobile-slider"
                                                readOnly={true}
                                                style={{
                                                    backgroundSize: `${
                                                        played * 100
                                                    }% 100%`,
                                                }}
                                                onTouchStart={
                                                    handleSeekMouseDown
                                                }
                                                onChange={handleSeekChange}
                                                onTouchEnd={handleSeekMouseUp}
                                                onMouseDown={
                                                    handleSeekMouseDown
                                                }
                                                onMouseUp={handleSeekMouseUp}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            />
                                        )}
                                    </>
                                )}
                        </MobileSliderContainer>
                    </Container>

                    <FullScreenContainer
                        showFullScreen={showFullScreen}
                        toggleScreen={toggleScreen}
                        mouseMoved={mouseMoved}
                        imageURL={
                            (joinedLiveroom &&
                                compressedUserPhoto(
                                    currentLiveroomHosts[0]?.user
                                )) ||
                            (playlist?.length && playlist[trackCount]?.image) ||
                            (playlist?.length &&
                                currentPlayingCatalog?.image) ||
                            catalogData?.image ||
                            '/img/dummy-content-or-catalog-image.svg'
                        }
                        onClick={handleScreenClick}
                    >
                        <PageLayout
                            showHeader={false}
                            isFullScreenPlayer={true}
                        >
                            <PlayerHeader>
                                <IconButton
                                    id="ele-9"
                                    className="ripple ripple-button"
                                    onClick={() =>
                                        dispatch(setToggleScreen(false))
                                    }
                                >
                                    <ExitFullScreenButton
                                        id="ele-10"
                                        loading="lazy"
                                        isRadio={
                                            currentPlayingCatalog?.type ===
                                            'radio'
                                        }
                                        src={'/img/exit-full-screen-player.svg'}
                                    />
                                </IconButton>

                                {!!playlist[trackCount]?.videoUrl && (
                                    <SwitchContainer>
                                        <AudioButton
                                            videoSelected={videoSelected}
                                            onClick={() => {
                                                setVideoSelected(false)
                                            }}
                                        >
                                            Audio
                                        </AudioButton>
                                        <VideoButton
                                            videoSelected={videoSelected}
                                            onClick={() =>
                                                setVideoSelected(true)
                                            }
                                        >
                                            Video
                                        </VideoButton>
                                    </SwitchContainer>
                                )}

                                {!showingAd && (
                                    <MobilePrevNext id="ele-1">
                                        <ThreeDotMenu
                                            id="ele-2"
                                            currentCatalog={
                                                currentPlayingCatalog ||
                                                catalogData
                                            }
                                            currentContent={playlist?.find(
                                                (singlePlaylist) =>
                                                    singlePlaylist?.id ===
                                                    currentTrackId
                                            )}
                                            isRadio={isRadio}
                                        />
                                    </MobilePrevNext>
                                )}
                            </PlayerHeader>

                            {!joinedLiveroom && !isVideo && (
                                <ThumbnailContainer>
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        {soundValue === 0 && <UnMuteTooltip />}
                                        <ThumbnailImage
                                            isVideo={isVideo}
                                            loading="lazy"
                                            isAudiobook={
                                                currentPlayingCatalog?.type ===
                                                'audiobook'
                                            }
                                            src={
                                                ads?.showingAd
                                                    ? companionAds?.player
                                                          ?.thumb
                                                        ? companionAds?.player
                                                              ?.thumb
                                                        : '/img/ad-thumb-default.svg'
                                                    : (playlist?.length &&
                                                          compressedImage(
                                                              playlist[
                                                                  trackCount
                                                              ]?.image,
                                                              playlist[
                                                                  trackCount
                                                              ]?.imageMeta
                                                          )) ||
                                                      (playlist?.length &&
                                                          compressedImage(
                                                              currentPlayingCatalog?.image,
                                                              currentPlayingCatalog?.imageMeta
                                                          )) ||
                                                      compressedImage(
                                                          catalogData?.image,
                                                          catalogData?.imageMeta
                                                      )
                                            }
                                        />

                                        {playlist[trackCount]?.isPremium && (
                                            <img
                                                src={
                                                    playlist[trackCount]
                                                        ?.earlyAccessFinishDate
                                                        ? '/img/early_access.svg'
                                                        : '/vip.svg'
                                                }
                                                alt="vip badge"
                                                className="vip-badge"
                                                style={{
                                                    position: 'absolute',
                                                    left: '0px',
                                                    bottom: '0px',
                                                    height: '19px',
                                                }}
                                            />
                                        )}
                                    </div>
                                </ThumbnailContainer>
                            )}

                            <ReactPlayerContainer
                                showFullScreen={showFullScreen}
                                style={getVideoSyle()}
                                onPointerOut={(e) => {
                                    if (e.pointerType === 'touch') {
                                        e.preventDefault()

                                        const element = document.querySelector(
                                            '.mobile-video-control-container'
                                        )

                                        if (element) {
                                            element.style.visibility = 'visible'

                                            setTimeout(function () {
                                                element.style.visibility =
                                                    'hidden'
                                            }, 3000)
                                        }
                                    }
                                }}
                                onMouseEnter={() => {
                                    const element = document.querySelector(
                                        '.mobile-video-control-container'
                                    )

                                    if (element) {
                                        element.style.visibility = 'visible'
                                    }
                                }}
                                onMouseLeave={() => {
                                    const element = document.querySelector(
                                        '.mobile-video-control-container'
                                    )

                                    if (element) {
                                        element.style.visibility = 'hidden'
                                    }
                                }}
                                onMouseMove={() => {
                                    if (!mouseMoved && showFullScreen) {
                                        setMouseMoved(true)

                                        setTimeout(function () {
                                            setMouseMoved(false)
                                        }, 4000)
                                    }
                                }}
                            >
                                {isVideo && soundValue === 0 && (
                                    <UnMuteTooltip />
                                )}
                                <ReactPlayer
                                    muted={soundValue !== 0 ? false : true}
                                    volume={soundValue / 100}
                                    playsinline
                                    playbackRate={playbackrRate}
                                    ref={playerRef}
                                    width="100%"
                                    height="100%"
                                    className="react-player"
                                    url={manipulatePlayableURL(playlist)}
                                    playing={playing && isReady}
                                    onStart={updateStartTime}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    onEnded={handleEnded}
                                    onReady={() => {
                                        dispatch(setPlaying(true))
                                        dispatch(setShowPlayer(true))
                                    }}
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                                    onError={(e) => {
                                        if (
                                            e ==
                                            'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'
                                        ) {
                                            dispatch(setPlaying(false))
                                            dispatch(setToggleScreen(true))
                                        }
                                    }}
                                ></ReactPlayer>

                                {!showFullScreen && (
                                    <VideoOverlayOnHover className="video-control-container">
                                        <VideoControlContainer>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()

                                                    const video =
                                                        document.querySelector(
                                                            'video'
                                                        )

                                                    video.requestPictureInPicture()
                                                    dispatch(
                                                        setToggleScreen(false)
                                                    )

                                                    const handleLeavePip =
                                                        () => {
                                                            dispatch(
                                                                setToggleScreen(
                                                                    true
                                                                )
                                                            )
                                                            video.removeEventListener(
                                                                'leavepictureinpicture',
                                                                handleLeavePip
                                                            )
                                                            setPreviouslyPlayedSeconds(
                                                                playedSeconds
                                                            )
                                                            const durationPlayed =
                                                                Math.round(
                                                                    playedSeconds
                                                                ) -
                                                                Math.round(
                                                                    previouslyPlayedSeconds
                                                                )
                                                            EventTracking(
                                                                'video_format_swithced',
                                                                {
                                                                    contentId:
                                                                        playlist[
                                                                            trackCount
                                                                        ]?.id,
                                                                    contentTitle:
                                                                        playlist[
                                                                            trackCount
                                                                        ]
                                                                            ?.title,
                                                                    catalogId:
                                                                        currentPlayingCatalog?.id,
                                                                    catalogTitle:
                                                                        currentPlayingCatalog?.title,
                                                                    switchFrom:
                                                                        'pip',
                                                                    switchTo:
                                                                        'standard',
                                                                    durationPlayed:
                                                                        durationPlayed,
                                                                    timestampPosition:
                                                                        Math.round(
                                                                            playedSeconds
                                                                        ),
                                                                    videoType:
                                                                        showFullScreen
                                                                            ? 'fullscreen'
                                                                            : toggleScreen
                                                                            ? 'standard'
                                                                            : 'pip',
                                                                }
                                                            )
                                                        }

                                                    video.addEventListener(
                                                        'leavepictureinpicture',
                                                        handleLeavePip
                                                    )
                                                    setPreviouslyPlayedSeconds(
                                                        playedSeconds
                                                    )
                                                    const durationPlayed =
                                                        Math.round(
                                                            playedSeconds
                                                        ) -
                                                        Math.round(
                                                            previouslyPlayedSeconds
                                                        )
                                                    EventTracking(
                                                        'video_format_swithced',
                                                        {
                                                            contentId:
                                                                playlist[
                                                                    trackCount
                                                                ]?.id,
                                                            contentTitle:
                                                                playlist[
                                                                    trackCount
                                                                ]?.title,
                                                            catalogId:
                                                                currentPlayingCatalog?.id,
                                                            catalogTitle:
                                                                currentPlayingCatalog?.title,
                                                            switchFrom:
                                                                'standard',
                                                            switchTo: 'pip',
                                                            durationPlayed:
                                                                durationPlayed,
                                                            timestampPosition:
                                                                Math.round(
                                                                    playedSeconds
                                                                ),
                                                            videoType:
                                                                showFullScreen
                                                                    ? 'fullscreen'
                                                                    : toggleScreen
                                                                    ? 'standard'
                                                                    : 'pip',
                                                        }
                                                    )
                                                }}
                                            >
                                                <img
                                                    src="/img/pip.svg"
                                                    alt="pip icon"
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setShowFullScreen(true)

                                                    fscreen.requestFullscreen(
                                                        document.body
                                                    )

                                                    if (isMobile) {
                                                        screen.orientation.lock(
                                                            'landscape'
                                                        )
                                                    }
                                                    setPreviouslyPlayedSeconds(
                                                        playedSeconds
                                                    )
                                                    const durationPlayed =
                                                        Math.round(
                                                            playedSeconds
                                                        ) -
                                                        Math.round(
                                                            previouslyPlayedSeconds
                                                        )
                                                    EventTracking(
                                                        'video_format_swithced',
                                                        {
                                                            contentId:
                                                                playlist[
                                                                    trackCount
                                                                ]?.id,
                                                            contentTitle:
                                                                playlist[
                                                                    trackCount
                                                                ]?.title,
                                                            catalogId:
                                                                currentPlayingCatalog?.id,
                                                            catalogTitle:
                                                                currentPlayingCatalog?.title,
                                                            switchFrom:
                                                                'standard',
                                                            switchTo:
                                                                'fullscreen',
                                                            durationPlayed:
                                                                durationPlayed,
                                                            timestampPosition:
                                                                Math.round(
                                                                    playedSeconds
                                                                ),
                                                            videoType:
                                                                showFullScreen
                                                                    ? 'fullscreen'
                                                                    : toggleScreen
                                                                    ? 'standard'
                                                                    : 'pip',
                                                        }
                                                    )
                                                }}
                                            >
                                                <img
                                                    src="/img/fullscreen.svg"
                                                    alt="full screen icon"
                                                />
                                            </IconButton>
                                        </VideoControlContainer>
                                    </VideoOverlayOnHover>
                                )}

                                {showFullScreen && (
                                    <MobileVideoControlContainer className="mobile-video-control-container">
                                        <div className="top-control">
                                            <EpisodeTitle>
                                                {playlist?.length &&
                                                    playlist[trackCount]?.title}
                                            </EpisodeTitle>

                                            <CatalogTitleText
                                                style={{
                                                    textAlign: 'start',
                                                }}
                                            >
                                                {currentPlayingCatalog
                                                    ? currentPlayingCatalog?.title
                                                    : catalogData.title}
                                            </CatalogTitleText>
                                        </div>

                                        <div className="mid-control">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleRewind()
                                                }}
                                            >
                                                <img src="/img/backward.svg" />
                                            </IconButton>

                                            <IconButton
                                                className="ripple-button ripple"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    dispatch(
                                                        setPlaying(!playing)
                                                    )
                                                }}
                                            >
                                                <img
                                                    loading="lazy"
                                                    className="play-button"
                                                    src={
                                                        playing
                                                            ? '/img/pause_outline.svg'
                                                            : '/img/play_outline.svg'
                                                    }
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleFastForward()
                                                }}
                                            >
                                                <img src="/img/forward.svg" />
                                            </IconButton>
                                        </div>

                                        <div className="bottom-control">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setShowFullScreen(false)

                                                    fscreen.exitFullscreen()

                                                    if (isMobile) {
                                                        screen.orientation.unlock()
                                                    }
                                                    setPreviouslyPlayedSeconds(
                                                        playedSeconds
                                                    )
                                                    const durationPlayed =
                                                        Math.round(
                                                            playedSeconds
                                                        ) -
                                                        Math.round(
                                                            previouslyPlayedSeconds
                                                        )
                                                    EventTracking(
                                                        'video_format_swithced',
                                                        {
                                                            contentId:
                                                                playlist[
                                                                    trackCount
                                                                ]?.id,
                                                            contentTitle:
                                                                playlist[
                                                                    trackCount
                                                                ]?.title,
                                                            catalogId:
                                                                currentPlayingCatalog?.id,
                                                            catalogTitle:
                                                                currentPlayingCatalog?.title,
                                                            switchFrom:
                                                                'fullscreen',
                                                            switchTo:
                                                                'standard',
                                                            durationPlayed:
                                                                durationPlayed,
                                                            timestampPosition:
                                                                Math.round(
                                                                    playedSeconds
                                                                ),
                                                            videoType:
                                                                showFullScreen
                                                                    ? 'fullscreen'
                                                                    : toggleScreen
                                                                    ? 'standard'
                                                                    : 'pip',
                                                        }
                                                    )
                                                }}
                                            >
                                                <img
                                                    src="/img/exit_full_screen.svg"
                                                    alt="exit full screen"
                                                />
                                            </IconButton>

                                            <DurationContainer>
                                                <DurationText>
                                                    {ads.showingAd
                                                        ? adDurationData.playedDurationInString
                                                        : formatCurrentTime}
                                                </DurationText>
                                                <DurationText>
                                                    {ads.showingAd
                                                        ? adDurationData.leftDurationInString
                                                        : formatDuration}
                                                </DurationText>
                                            </DurationContainer>

                                            <PlayerSlider
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                type="range"
                                                min={0}
                                                max={0.999999}
                                                step="any"
                                                value={played}
                                                // readOnly={true}
                                                onTouchStart={
                                                    handleSeekMouseDown
                                                }
                                                onChange={handleSeekChange}
                                                onTouchEnd={handleSeekMouseUp}
                                                onMouseDown={
                                                    handleSeekMouseDown
                                                }
                                                onMouseUp={handleSeekMouseUp}
                                                className="slider-fullscreen"
                                                style={{
                                                    backgroundSize: `${
                                                        played * 100
                                                    }% 100%`,
                                                }}
                                            />
                                        </div>
                                    </MobileVideoControlContainer>
                                )}
                            </ReactPlayerContainer>

                            <PlayingContentInformation>
                                {(currentPlayingCatalog?.type === 'radio' ||
                                    joinedLiveroom) && (
                                    <HeaderContainer
                                        isLiveroom={joinedLiveroom}
                                        showingAd={ads.showingAd}
                                    >
                                        {!ads.showingAd && (
                                            <>
                                                {!joinedLiveroom ? (
                                                    <RadioTitle>
                                                        {
                                                            currentPlayingCatalog?.title
                                                        }
                                                    </RadioTitle>
                                                ) : (
                                                    <LiveroomHeaderContainer>
                                                        <LiveroomTitle>
                                                            {
                                                                currentLiveroom?.title
                                                            }
                                                        </LiveroomTitle>
                                                        <LiveHeaderParticipantCountAndText>
                                                            {`${liveroomParticipantCount} Peserta`}
                                                            {currentLiveroom?.showRecording && (
                                                                <>
                                                                    <Ellipse
                                                                        loading="lazy"
                                                                        color={
                                                                            '#C4C4C4'
                                                                        }
                                                                        src={
                                                                            '../img/Ellipse.svg'
                                                                        }
                                                                    />
                                                                    <RecordedRoomBadge>
                                                                        <RecordIcon src="../img/record_icon.svg" />
                                                                        <RecordText>
                                                                            REC
                                                                        </RecordText>
                                                                    </RecordedRoomBadge>
                                                                </>
                                                            )}
                                                            {currentLiveroom?.type ===
                                                                'private' && (
                                                                <>
                                                                    <Ellipse
                                                                        loading="lazy"
                                                                        color={
                                                                            '#C4C4C4'
                                                                        }
                                                                        src={
                                                                            '../img/Ellipse.svg'
                                                                        }
                                                                    />
                                                                    <VIPLiveBadge>
                                                                        <VIPStarIcon
                                                                            loading="lazy"
                                                                            src={
                                                                                '../img/rating_fill.svg'
                                                                            }
                                                                        />
                                                                        <VIPText>
                                                                            VIP
                                                                        </VIPText>
                                                                    </VIPLiveBadge>
                                                                </>
                                                            )}
                                                        </LiveHeaderParticipantCountAndText>
                                                    </LiveroomHeaderContainer>
                                                )}
                                            </>
                                        )}

                                        {joinedLiveroom && (
                                            <InfoOutline
                                                loading="lazy"
                                                src={'/img/info_icon.svg'}
                                                onClick={() =>
                                                    dispatch(
                                                        setShowDownloadPrompt(
                                                            true
                                                        )
                                                    )
                                                }
                                            />
                                        )}
                                    </HeaderContainer>
                                )}

                                {joinedLiveroom && (
                                    <UserListMainContainer>
                                        <UserListInnerContainer>
                                            <UserListHeadingContainer>
                                                <UserListHeadingText>
                                                    {`${rearrangedUsers?.length} Pembicara`}
                                                </UserListHeadingText>
                                            </UserListHeadingContainer>
                                            <UserGridDataContainer>
                                                {rearrangedUsers?.map(
                                                    ({
                                                        user,
                                                        participantUserId,
                                                        isActive,
                                                        type,
                                                        id,
                                                    }) => {
                                                        const {
                                                            displayName = '',
                                                            userName = '',
                                                            photo = '',
                                                            isVerified = '',
                                                        } = user || {}
                                                        return (
                                                            <SingleUserContainer
                                                                key={id}
                                                            >
                                                                <UserImageContainer>
                                                                    <UserImage
                                                                        loading="lazy"
                                                                        src={compressedUserPhoto(
                                                                            user
                                                                        )}
                                                                    />
                                                                </UserImageContainer>
                                                                <Link
                                                                    href={`/user/${user?.id}`}
                                                                    prefetch={
                                                                        false
                                                                    }
                                                                >
                                                                    <a>
                                                                        <UsernameContainer>
                                                                            <UsernameText
                                                                                isVerified={
                                                                                    isVerified
                                                                                }
                                                                            >
                                                                                {`@${
                                                                                    participantUserId &&
                                                                                    localStorage.getItem(
                                                                                        'appUserID'
                                                                                    ) ===
                                                                                        participantUserId
                                                                                        ? 'You'
                                                                                        : userName ||
                                                                                          displayName
                                                                                }`}
                                                                            </UsernameText>
                                                                            {isVerified && (
                                                                                <VerifiedUserIcon
                                                                                    loading="lazy"
                                                                                    src={
                                                                                        '../img/verify_Icon.svg'
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </UsernameContainer>
                                                                    </a>
                                                                </Link>

                                                                <UserRole>
                                                                    <UserRoleText>
                                                                        {type ===
                                                                        'speaker'
                                                                            ? 'Pembicara'
                                                                            : type}
                                                                    </UserRoleText>
                                                                </UserRole>
                                                            </SingleUserContainer>
                                                        )
                                                    }
                                                )}
                                            </UserGridDataContainer>
                                        </UserListInnerContainer>
                                    </UserListMainContainer>
                                )}

                                {!joinedLiveroom && (
                                    <>
                                        {!ads.showingAd && (
                                            <EpisodeTitle isRadio={isRadio}>
                                                {isRadio && (
                                                    <img
                                                        src="/img/music.svg"
                                                        alt="music icon"
                                                    />
                                                )}

                                                <marquee
                                                    behavior="scroll"
                                                    direction="left"
                                                >
                                                    {playlist?.length &&
                                                        playlist[trackCount]
                                                            ?.title}
                                                </marquee>

                                                {/* <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                        maxWidth: '100%',
                                                    }}
                                                >
                                                    {playlist?.length &&
                                                        playlist[trackCount]
                                                            ?.title}
                                                </span> */}
                                            </EpisodeTitle>
                                        )}

                                        {!ads.showingAd && (
                                            <>
                                                <CatalogTitleAndFollowContainer
                                                    isRadio={isRadio}
                                                    id="ele-3"
                                                >
                                                    <CatalogTitleText
                                                        id="ele-4"
                                                        onClick={(e) => {
                                                            router.push(
                                                                `/catalog/${
                                                                    currentPlayingCatalog
                                                                        ? currentPlayingCatalog?.id
                                                                        : catalogData.id
                                                                }`
                                                            )
                                                            dispatch(
                                                                setToggleScreen(
                                                                    false
                                                                )
                                                            )
                                                        }}
                                                    >
                                                        {` Oleh ${
                                                            currentPlayingCatalog
                                                                ? currentPlayingCatalog?.title
                                                                : catalogData.title
                                                        }`}
                                                    </CatalogTitleText>

                                                    <span>
                                                        {NumberConversion(
                                                            followers
                                                        )}
                                                    </span>

                                                    <FollowButtonContainer
                                                        id="ele-5"
                                                        subscribed={subscribed}
                                                        onClick={async (e) => {
                                                            e.stopPropagation()

                                                            if (!isLoggedin) {
                                                                dispatch(
                                                                    setShowLoginPrompt(
                                                                        true
                                                                    )
                                                                )
                                                                return
                                                            }

                                                            const catalogId =
                                                                currentPlayingCatalog?.id

                                                            await subscribeToCatalog(
                                                                {
                                                                    catalogId,
                                                                    userId,
                                                                    token,
                                                                    subscribed,
                                                                }
                                                            )

                                                            await mutate()
                                                        }}
                                                    >
                                                        {subscribed ? (
                                                            <FollowText id="ele-6">
                                                                {isRadio
                                                                    ? 'Favorited'
                                                                    : 'Subscribed'}
                                                            </FollowText>
                                                        ) : (
                                                            <FollowText id="ele-7">
                                                                {currentPlayingCatalog?.type ===
                                                                'radio'
                                                                    ? 'Favorite'
                                                                    : currentPlayingCatalog?.type ===
                                                                      'audiobook'
                                                                    ? 'Simpan'
                                                                    : 'Subscribe'}
                                                            </FollowText>
                                                        )}
                                                    </FollowButtonContainer>
                                                </CatalogTitleAndFollowContainer>

                                                {/* {currentPlayingCatalog?.type ===
                                                    'radio' && (
                                                    <ListensInformation>
                                                        <ListensIcon
                                                            loading="lazy"
                                                            src={
                                                                '/img/listen-icon.svg'
                                                            }
                                                        />
                                                        <ListensText>
                                                            {listenerCount >= 0
                                                                ? listenerCount
                                                                : 0}
                                                        </ListensText>
                                                    </ListensInformation>
                                                )} */}
                                            </>
                                        )}
                                    </>
                                )}
                            </PlayingContentInformation>

                            {!joinedLiveroom && (
                                <PlayerContainer>
                                    {!(
                                        currentPlayingCatalog?.type === 'radio'
                                    ) && (
                                        <DurationContainer>
                                            <DurationText>
                                                {ads.showingAd
                                                    ? adDurationData.playedDurationInString
                                                    : formatCurrentTime}
                                            </DurationText>
                                            <DurationText>
                                                {ads.showingAd
                                                    ? adDurationData.leftDurationInString
                                                    : formatDuration}
                                            </DurationText>
                                        </DurationContainer>
                                    )}

                                    {!isRadio && (
                                        <>
                                            {ads.showingAd ? (
                                                <PlayerSlider
                                                    type="range"
                                                    min={0}
                                                    max={0.999999}
                                                    step="any"
                                                    value={
                                                        adDurationData.playedSeconds
                                                    }
                                                    className="slider-fullscreen"
                                                    style={{
                                                        backgroundSize: `${
                                                            adDurationData.playedSeconds *
                                                            100
                                                        }% 100%`,
                                                    }}
                                                />
                                            ) : (
                                                <PlayerSlider
                                                    type="range"
                                                    min={0}
                                                    max={0.999999}
                                                    step="any"
                                                    value={played}
                                                    // readOnly={true}
                                                    onTouchStart={
                                                        handleSeekMouseDown
                                                    }
                                                    onChange={handleSeekChange}
                                                    onTouchEnd={
                                                        handleSeekMouseUp
                                                    }
                                                    onMouseDown={
                                                        handleSeekMouseDown
                                                    }
                                                    onMouseUp={
                                                        handleSeekMouseUp
                                                    }
                                                    className="slider-fullscreen"
                                                    style={{
                                                        backgroundSize: `${
                                                            played * 100
                                                        }% 100%`,
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}

                                    <ControlsContainer
                                        isRadio={
                                            currentPlayingCatalog?.type ===
                                            'radio'
                                        }
                                        showingAd={ads.showingAd}
                                    >
                                        {!isRadio && !ads.showingAd && (
                                            <PlaybackRateMenu
                                                isMobile={true}
                                                playbackRate={playbackrRate}
                                                setPlaybackRate={
                                                    setPlaybackRate
                                                }
                                            />
                                        )}

                                        {!isRadio && !showingAd && (
                                            <ControlsIcon
                                                loading="lazy"
                                                src={'/img/backward.svg'}
                                                onClick={() => handleRewind()}
                                            />
                                        )}

                                        <IconButton
                                            className="ripple-button ripple"
                                            onClick={() => {
                                                if (
                                                    ads.showingAd &&
                                                    ads.adPlaying
                                                ) {
                                                    adsManager.pause()
                                                    dispatch(
                                                        initializeAds({
                                                            adPlaying: false,
                                                        })
                                                    )
                                                    adsManager.pause()
                                                } else if (
                                                    ads.showingAd &&
                                                    !ads.adPlaying
                                                ) {
                                                    adsManager.resume()
                                                    dispatch(
                                                        initializeAds({
                                                            adPlaying: true,
                                                        })
                                                    )
                                                } else {
                                                    dispatch(
                                                        setPlaying(!playing)
                                                    )
                                                }
                                            }}
                                        >
                                            <ControlsPlayIcon
                                                loading="lazy"
                                                className="play-button"
                                                isRadio={
                                                    currentPlayingCatalog?.type ===
                                                    'radio'
                                                }
                                                src={
                                                    playing || ads.adPlaying
                                                        ? !(
                                                              currentPlayingCatalog?.type ===
                                                              'radio'
                                                          )
                                                            ? '/img/pause_mobile.svg'
                                                            : '/img/stop_mobile.svg'
                                                        : '/img/play_mobile.svg'
                                                }
                                            />
                                        </IconButton>

                                        {!(
                                            currentPlayingCatalog?.type ===
                                            'radio'
                                        ) &&
                                            !ads.showingAd && (
                                                <ControlsIcon
                                                    loading="lazy"
                                                    src={'/img/forward.svg'}
                                                    onClick={
                                                        () =>
                                                            handleFastForward()
                                                        // dispatch(setShowDownloadPrompt(true))
                                                    }
                                                />
                                            )}
                                        {!ads.showingAd && !isRadio && (
                                            <ControlsIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                loading="lazy"
                                                isRadio={
                                                    currentPlayingCatalog?.type ===
                                                    'radio'
                                                }
                                                src={'/img/sleep.svg'}
                                                onClick={() =>
                                                    dispatch(
                                                        setShowDownloadPrompt(
                                                            true
                                                        )
                                                    )
                                                }
                                            />
                                        )}
                                    </ControlsContainer>
                                    {showingAd && companionAds.player?.link && (
                                        <AdButtonOnMobile
                                            href={companionAds.player.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => {
                                                if (session?.token) {
                                                    AmplitudeEventTracking(
                                                        'ad clicked',
                                                        amplitudeAdEventParams,
                                                        session
                                                    )
                                                    EventTracking(
                                                        'ad_clicked',
                                                        firebaseAdEventParams,
                                                        session
                                                    )
                                                }
                                            }}
                                        >
                                            Kunjungi Iklan
                                        </AdButtonOnMobile>
                                    )}
                                </PlayerContainer>
                            )}
                        </PageLayout>
                    </FullScreenContainer>
                </>
            </>
        )

    return null
}

export default AudioPlayer
